<template>
    <div>
        <ckeditor :editor="editor" v-model="editorData" @input="updateField" :config="editorConfig" ></ckeditor>
    </div>
</template>
<script>
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import UploadAdapter from '@/utils/CkEditorUploadAdapter';

    export default {
        created() {
        },
        props: ['editor_data'],
        data () {
            return {
                editor: ClassicEditor,
                editorData: this.editor_data ? this.editor_data : '',
                editorConfig: {
                    // The configuration of the editor.
                    extraPlugins: [this.uploader],
                }
            }
        },
        watch: {
            value: function() {
                this.editorData = this.value;
            }
        },
        mounted() {
        },
        methods: {
            uploader(editor) {
                editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                    return new UploadAdapter( loader );
                };
            },
            updateField: function() {
                this.$emit('input',String(this.editorData));
            }
        }
    }
</script>

<style scoped>
</style>