import config from "../config";
export default class UploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        return this.loader.file.then( file => new Promise(((resolve, reject) => {
            this._initRequest();
            this._initListeners( resolve, reject, file )
            this._sendRequest( file );
        })))
    }

    _initRequest() {
        const xhr = this.xhr = new XMLHttpRequest();
        xhr.open('POST', `${config.apiBaseUrl}/image/upload`, true);
        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        xhr.responseType = 'json';
    }

    _initListeners(resolve, reject, file) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = '파일을 업로드 할 수 없습니다.'
        console.dir(file)
        console.dir(loader)

        xhr.addEventListener('error', () => {reject(genericErrorText)})
        xhr.addEventListener('abort', () => {reject()})
        xhr.addEventListener('load', () => {
            const response = xhr.response
            // console.dir(xhr)
            // console.dir(response)
            if(!response || response.error) {

                return reject( response && response.error ? response.error.message : genericErrorText );
            }

            /*if(uploadImageArray) {
                uploadImageArray.push({
                    fileNo:response.fileNo,
                    url:response.url
                })
            }*/

            resolve({
                // response => fileNo 넘어옴 이것도 처리하는 방식 로직 생각해야 함
                default:response.url,
                // fileNo:response.fileNo
                // 여기서 이미지 주소를 반환을 해야, 서버쪽으로 보낼 때 주소값이 렌더링 되서 나감 ㅠ_ㅠ
            })
        })
    }

    _sendRequest(file) {
        const data = new FormData()
        data.append('upload',file)
        this.xhr.send(data)
    }
}
